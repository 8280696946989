import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import CancelIcon from '@mui/icons-material/Cancel';
import { FormHelperText, Tooltip, Stack, IconButton, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales } from '../../locales';
import { fData } from '../../utils/formatNumber';
import { Upload, UploadAvatar, UploadBox } from '../upload';
import Iconify from '../iconify';
// ----------------------------------------------------------------------
export function RHFUploadAvatar({ name, helperText, ...other }) {
    const { translate } = useLocales();
    const { control, setValue } = useFormContext();
    const handleDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const newFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
        });
        if (file) {
            setValue(name, newFile, { shouldValidate: true });
        }
    }, [setValue, name]);
    const handleRemoveFile = () => {
        setValue(name, '', { shouldValidate: true });
    };
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(_Fragment, { children: [_jsx(UploadAvatar, { ModelImage: "Logo *", accept: {
                        'image/*': [],
                    }, error: !!error, file: field.value, onDrop: handleDrop, onDelete: handleRemoveFile, helperText: _jsx(Typography, { variant: "caption", sx: {
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                        }, children: _jsxs(Stack, { sx: {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '20px',
                                width: '100%',
                            }, children: [_jsxs(_Fragment, { children: [`${translate('Allowed *.jpeg, *.jpg, *.png, *.gif')}`, _jsx("br", {}), `${translate(`max size of`)} `, fData(5000000), field.value && (_jsx(IconButton, { onClick: handleRemoveFile, color: "error", "aria-label": "delete", children: _jsx(CancelIcon, { color: "error" }) }))] }), _jsx(Tooltip, { title: "Logo *", placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: { cursor: 'help', color: '#9AA6B2' } }) })] }) }), ...other }), !!error && (_jsx(FormHelperText, { error: true, sx: { px: 2, textAlign: 'center' }, children: `${translate(error?.message)}` }))] })) }));
}
// ----------------------------------------------------------------------
export function RHFUploadBox({ name, ...other }) {
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsx(UploadBox, { files: field.value, error: !!error, ...other })) }));
}
// ----------------------------------------------------------------------
export function RHFUpload({ name, multiple, helperText, files, label, ...other }) {
    const { control, setValue } = useFormContext();
    const { translate } = useLocales();
    const handleDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const newFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
        });
        if (file) {
            setValue(name, newFile, { shouldValidate: true });
        }
    }, [setValue, name]);
    const handleRemoveFile = () => {
        setValue(name, '', { shouldValidate: true });
    };
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => multiple ? (_jsx(Upload, { multiple: true, accept: { 'image/*': [] }, files: field.value, onDrop: handleDrop, onDelete: handleRemoveFile, error: !!error, helperText: (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { px: 2 }, children: error ? `${translate(error?.message)}` : `${translate(helperText)}` })), ...other })) : (_jsx(Upload, { accept: { 'image/*': [] }, thumbnail: field.value, file: field.value, error: !!error, onDrop: handleDrop, onDelete: handleRemoveFile, helperText: (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { px: 2 }, children: error ? `${translate(error?.message)}` : `${translate(helperText)}` })), ...other })) }));
}
